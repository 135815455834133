import React from 'react';
import './Projects.css';
import p1 from "./p1.jpg"
import p2 from "./p2.png"
import p3 from "./p3.png"
import p4 from "./p4.png"
// Example array of projects with photos
const projects = [
  {
    title: 'LecturePal',
    info: 'LecturePal is a AI notetaking app designed to help university students save time and improve their in-class retention. It features a user login/register system, user storage, and password recovery that is common for many web applications. Users can upload lecture slides and click a button that generates clean precise notes utiilizing AI in seconds.',
    link: 'https://lecturepal.ca/',
    photo: p2, // Replace with actual image URLs
  },
  {
    title: 'HR Proactive',
    info: 'HR Proactive is a leading HR firm in Canada we have worked with on a number of projects. This site in particular is designed to sell an online training product used by numerous well-known Canadian Companies.',
    link: 'https://antispamtraining.com/',
    photo: p3, // Replace with actual image URLs
  },
  {
    title: 'FitVault',
    info: 'FitVault is a fitness app that allows trainers to post workouts for there clients online for free. FitVault is a complete web solution with Apple App Store, Google Play Store, and website functionality.',
    link: 'https://apps.apple.com/za/app/fitvault-fitness-app/id6443820434',
    photo: p4, // Replace with actual image URLs
  },
  // Add more projects as needed
];

const Projects = () => {
  return (
    <div className="projects">  
     <h1>Past Projects</h1>
      <p>Check out our past projects! Click the project to see it live.</p>
      {projects.map((project, index) => (
        <a 
          key={index}
          href={project.link}
          className="project-box"
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className="project-content">
            <div className="project-image">
              <img src={project.photo} alt={project.title} />
            </div>
            <div className="project-text">
              <h2>{project.title}</h2>
              <p>{project.info}</p>
            </div>
          </div>
        </a>
      ))}
      <p style={{padding:"30px"}}>For a complete list of over 50 websites and 15 applications, contact us today!</p>
    </div>
  );
};

export default Projects;


