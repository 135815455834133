import React from 'react';
import './Contact.css';
import { FaMapMarkerAlt, FaPhone, FaEnvelope } from 'react-icons/fa';

const Contact = () => {
  return (
    <div className="contact">
  <h1>Contact Page</h1>
    <p>Get in touch with us!</p>
      <div className="section-box">
  
        <div className="contact-info">
          <a 
            href="https://www.google.com/maps?q=Your+Address" 
            className="contact-item" 
            target="_blank" 
            rel="noopener noreferrer"
          >
            <FaMapMarkerAlt className="contact-icon" />
            <div className="contact-text">
              
              <p>19 Patricia St. Thorold, ON L2V 3V8
              </p>
            </div>
          </a>
          <a 
            href="tel:+1234567890" 
            className="contact-item"
          >
            <FaPhone className="contact-icon" />
            <div className="contact-text">
         
              <p>+1 (289) 698-7602</p>
            </div>
          </a>
          <a 
            href="mailto:youremail@example.com" 
            className="contact-item"
          >
            <FaEnvelope className="contact-icon" />
            <div className="contact-text">
             
              <p>mattduarte56@gmail.com</p>
            </div>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Contact;

