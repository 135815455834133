import React from 'react';
import './Home.css';
import onlinePresenceImage from './p1.jpg'; // Update with the correct path
import { useNavigate } from 'react-router-dom';

const Home = () => {
  const navigate = useNavigate();
  return (
    <div className="home">
      {/* Title and Image Section */}
      <section className="title-section">
        <div className="title-content">
          <h1>Turning Your Vision into a Digital Reality</h1>
          <p>Let us help you build a powerful online presence to reach your goals.</p>
        </div>
        <div className="image-content">
        <img 
            src={onlinePresenceImage} 
            alt="Grow Your Online Presence" 
            style={{ 
              maxWidth: '100%', 
              height: 'auto', 
              borderRadius: '10px', 
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' 
            }} 
          />
        </div>
      </section>

      {/* What We Do Section */}
      <section className="what-we-do">
        <div className="service-box">
          <h2>Web Development</h2>
          <p>We create modern, responsive websites that attract customers and drive results. We have helped small businesses in Ontario establish their online presence for the last 5 years! See our past projects for more detail.</p>
        </div>
        <div className="service-box">
          <h2>App Development</h2>
          <p>Take your business to the next level. We have successfully completed projects across a number of industries. Find out how we can help today!</p>
        </div>
      </section>

      {/* See Our Work Button */}
      <div className="see-work">
        <button className="see-work-button"  onClick={() => navigate('/projects')}>See Our Work</button>
      </div>

      {/* Contact Form */}
      {/* <section className="contact-form">
        <h2>Contact Us</h2>
        <form>
          <div className="form-group">
            <label>Name</label>
            <input type="text" name="name" required />
          </div>
          <div className="form-group">
            <label>Email</label>
            <input type="email" name="email" required />
          </div>
          <div className="form-group">
            <label>Message</label>
            <textarea name="message" required></textarea>
          </div>
          <button type="submit">Send Message</button>
        </form>
      </section> */}
    </div>
  );
};

export default Home;

